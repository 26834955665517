import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <div className="bg-[#121212] text-[#CBC3CE] max-w-xl w-[560px] justify-center top-0 pt-5 font-serif italic">
      <div className="flex justify-center bg-[#424242] w-[560px] px-11 py-2 my-12 sm:px-[158px]">
        This site is old.
      </div>
      <div className="flex flex-col sm:flex-row justify-center text-center sm:text-left gap-2  text-sm md:text-xs my-6">
        <div className="flex-2">
          <p>Site written in JavaScript using React Library (CRA).</p>
          <p>
            Hosted on&nbsp;
            <a
              href="https://linkbinary.com/"
              target="none"
              className="underline text-[#F2932C]"
            >
              LinkBinary Cloud
            </a>
            .
          </p>
        </div>
        <div className="sm:flex flex-1 items-center sm:justify-end py-2">
          <NavLink to="/inspiration" className="underline">
            Inspiration
          </NavLink>{" "}
          <span className="not-italic"> &nbsp;| &copy; &</span>
          &nbsp;
          <NavLink to="/policies" className="underline">
            policies
          </NavLink>
          &nbsp;2022
        </div>
      </div>
    </div>
  );
};

export default Footer;
