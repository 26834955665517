import React from "react";

const Blog = () => {
  return (
    <div className="text-white min-h-screen">
      This page is currently under development. Check back soon... :)
    </div>
  );
};

export default Blog;
