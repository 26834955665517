import React, { useState } from "react";
import { Link } from "react-router-dom";
import photo from "../img/my.JPG";
import { FaLinkedinIn } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";

const Home = () => {
  const [placeholder, setPh] = useState("join the mailing list");

  const handleClick = () => {
    setPh("coming soon..");
  };

  return (
    <div class="bg-[#121212] text-white font-primary max-w-xl justify-center top-0 pt-5">
      {/* Basic Info */}
      <div class="flex flex-col-reverse items-start md:items-center md:justify-center md:flex md:flex-row sm:gap-24">
        <div class="md:my-16">
          <p>Namaste, I'm</p>
          <p className="text-5xl font-bold">
            <span className="hover:text-[#F2932C] duration-1000 cursor-default">
              K
            </span>
            <span className="hover:text-[#F2932C] duration-1000 cursor-default">
              a
            </span>
            <span className="hover:text-[#F2932C] duration-1000 cursor-default">
              u
            </span>
            <span className="hover:text-[#F2932C] duration-1000 cursor-default">
              s
            </span>
            <span className="hover:text-[#F2932C] duration-1000 cursor-default">
              h
            </span>
            <span className="hover:text-[#F2932C] duration-1000 cursor-default">
              a
            </span>
            <span className="hover:text-[#F2932C] duration-1000 cursor-default">
              l
            </span>
            <span className="text-[#F2932C]">&nbsp;Rijal</span>
          </p>
          <p className="font-secondary italic">Software Engineer</p>
          <p className="font-secondary italic text-[#949494]">
            Student of Science at Dhulabari Secondary School
          </p>
          <p className="font-secondary italic text-[#949494]">
            I love creating things. <span className="text-white">More</span>
            &nbsp;
            <Link to="/about" className="underline text-[#F2932C]">
              here
            </Link>
            .
          </p>
        </div>
        <div class="my-16">
          <div
            class="rounded-full overflow-hidden items-center justify-center w-[120px] border-[2px] border-white saturate-[50%] hover:saturate-100 hover:border-[#F2932C]"
            aria-label="I am the greatest person of this world"
          >
            <img src={photo} alt="profile-pic" width={120} />
          </div>
        </div>
      </div>
      {/* Social Icons and Mailing List */}
      <div class="flex items-center justify-center flex-col sm:flex-row mx-auto my-20 sm:my-8">
        <div class="flex items-center gap-12 px-6 py-4 sm:py-2 border-t-[1px] border-l-0 sm:border-[1px] sm:gap-10 sm:border-l-0">
          <a
            href="https://www.linkedin.com/in/kaushal-rijal-772902213"
            target="none"
          >
            <FaLinkedinIn size={20} />
          </a>
          <a href="https://www.github.com/kaushalrijal" target="none">
            <FaGithub size={20} />
          </a>
          <a href="https://twitter.com/kaushalSym" target="none">
            <FaTwitter size={20} />
          </a>
          <a href="https://m.facebook.com/rijalkaushal" target="none">
            <FaFacebookF size={20} />
          </a>
          <a href="https://instagram.com/kaushalSym" target="none">
            <BsInstagram size={20} />
          </a>
        </div>
        <div class="border-[1px]" onClick={handleClick}>
          <input
            type="email"
            placeholder={placeholder}
            class="bg-[#121212] p-2 text-sm border-none sm:p-1.5"
            name="Email"
            disabled
          />
          <input
            type="submit"
            value="JOIN"
            className="bg-white text-black p-2 sm:p-1.5"
            onClick={handleClick}
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
