import React from "react";

const NavSub = () => {
  return (
    <div className="text-xs text-white font-secondary italic py-2 flex flex-column w-[560px] justify-center items-center border-b border-b-[#374151]">
      Looking for investment shots in a tech startup?&nbsp;
      <a href="/" class="underline">
        Contact
      </a>
      &nbsp;me.
    </div>
  );
};

export default NavSub;
