import React, { useRef, useState } from "react";
import { BsTwitter, BsMessenger } from "react-icons/bs";
import { BiMailSend } from "react-icons/bi";
import { MdLocationOn } from "react-icons/md";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const formRef = useRef();
  const [Message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_e3zis5l",
        "template_38dokjk",
        formRef.current,
        "SOcD2VtKu8d_p420T"
      )
      .then(
        (result) => {
          console.log(result.text);
          document.getElementById("name").value = "";
          document.getElementById("email").value = "";
          document.getElementById("message").value = "";
          setMessage("Your message has been sent. I'll get back to you soon.");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div className="max-w-xl w-[560px] font-serif italic text-white text-left">
      {/* Contact Details */}
      <div className="flex flex-col my-4 py-4 border-b border-b-[#374151]">
        <h1 className="text-2xl md:text-4xl font-bold my-4">
          <span className="text-[#F2932C]">Contact</span> me.
        </h1>
        <div>
          <ul>
            <li className="flex items-center gap-2">
              <BsTwitter />

              <a
                href="https://twitter.com/kaushalSym"
                target="none"
                className="underline text-[#F2932C]"
              >
                @kaushalSym
              </a>
            </li>
            <li className="flex items-center gap-2">
              <BsMessenger />

              <a
                href="https://m.me/rijalkaushal"
                target="none"
                className="underline text-[#F2932C]"
              >
                @rijalkaushal
              </a>
            </li>
            <li className="flex items-center gap-2">
              <BiMailSend />

              <a
                href="mailto:kaushalrijal091@gmail.com"
                target="none"
                className="underline text-[#F2932C]"
              >
                kaushalrijal091@gmail.com
              </a>
            </li>
            <li className="flex items-center gap-2">
              <MdLocationOn />
              <a
                href="https://cutt.ly/wSlmyqp"
                target="none"
                className="underline text-[#F2932C]"
              >
                47.565996984850905, -53.59102358908282 (pls don't track me)
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/* Contact Form */}
      <div>
        <h2 className="text-xl font-semibold mb-10">
          <span className="text-[#F2932C]">Hit</span> my mailbox
        </h2>
        <div>
          <form
            className="flex flex-col gap-2"
            onSubmit={handleSubmit}
            ref={formRef}
          >
            <input
              type="text"
              placeholder="Name"
              id="name"
              class="placeholder:font-serif placeholder:italic bg-[#121212] p-2 text-md border-1 border-[#374151] text-md sm:p-[4
                px] font-primary"
              name="name"
              required
            />
            <input
              type="email"
              placeholder="Email"
              id="email"
              class="placeholder:font-serif placeholder:italic bg-[#121212] p-2 text-md border-1 border-[#374151] text-md sm:p-[4
                px] font-primary"
              name="email"
              required
            />
            <textarea
              placeholder="Your Message"
              id="message"
              class="placeholder:font-serif placeholder:italic bg-[#121212] p-2 text-md border-[1px] max-h-[200px] border-[#374151] text-md sm:p-[4
                px] font-primary"
              name="message"
              required
            ></textarea>
            <input
              type="submit"
              value="SEND"
              className="w-full bg-[#F2932C] p-2 font-primary text-black font-semibold cursor-pointer"
            />
          </form>
          <div className="w-full py-4 text-green not-italic text-sm font-primary text-green-500">
            {Message}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
