import React from 'react';
import Home from "./components/Home";
import Blog from "./components/Blog";
import { Routes, Route } from "react-router-dom"
import Footer from './components/Footer';
import Inspiration from './pages/Inspiration';
import About from './components/About';
import NavLogo from './components/NavLogo';
import NavLinks from './components/NavLinks';
import NavSub from './components/NavSub';
// import Navbar from './components/Navbar';
// import Guestbook from "./components/Guestbook";
import Contact from "./components/Contact";
// import Resume from "./components/Resume";
// import Tools from "./components/Tools";


function App() {
  return (
    <div className='flex min-h-screen flex-col items-center bg-[#121212]'>
      <NavLogo />
      <NavLinks />
      <NavSub />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/guestbook' element={<Blog />} />
        <Route path='/blog' element={<Blog />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/resume' element={<Blog />} />
        <Route path='/tools' element={<Blog />} />
        <Route path="/inspiration" element={<Inspiration />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
