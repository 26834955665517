import React from "react";
import { NavLink } from "react-router-dom";

const NavLinks = () => {
  return (
    <div class="flex justify-center sticky top-0 z-999 bg-opacity-20 backdrop-blur-sm">
      <div class="max-w-xl w-[560px] space flex justify-center border-t-[1px] border-t-[#374151] border-b-[1px] border-b-[#374151] px-0 z-999">
        {/* <!-- Home Icon --> */}
        <div class="rounded-md bg-[#424242] p-2 hover:cursor-pointer hover:border-2 hover:border-solid hover:border-[#555A65] my-auto mr-3">
          <NavLink to="/">
            <img
              width="18"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAABTUlEQVRIie2TvUoDQRSFz50NEVQiWimiWPlTiGJh7RY2bsaYTgk2dr6FT+Aj2AqL7k92Z4VUCwYrfQKtbLTwASziXgt1xWySTbIRFHLKmTPfOZeZAf67qFuj53nzDdZsAMjRW1lK+TiwAMdRqxBQAOY+Tz0xsSwXi3dpZ0WawXXVNgTqMRwAGDMUIbSqqpgpwHWDI/5oXkju0rgAHNsNjjsxtFaLzEzrG5snIJy283wVJIKxf1CZWl5arIVhyIkazQumaebzI6NnIKp0atai1cVEYexQ1/XXtgG+7082ImEBvNUT/Jt2oyEqSSlfEgG2fbVAWhQAWOkLHosfNOIdKeV9HGB53ppgoQDMZoPHeiaGLJWMWwEAAuJygHAAmGbic6DFJTtVlXgJvWhv1/jBTP1oWZXr1tjcrNtJf32CYcAfDCBwPeFiXPfrGypV7xTKYC/KfW8ZAAAAAElFTkSuQmCC"
              alt="labalaba"
            />
          </NavLink>
        </div>
        {/* <!-- Nav Items --> */}
        <div class="flex flex-col justify-center p-4 md:mb-0 md:flex-row md:py-5 lg:py-5 font-bold font-primary text-xs">
          <ul class="flex space-x-6 md:mr-2">
            <li class="rounded-sm hover:bg-[#424242] p-[3px]">
              <NavLink
                to="/about"
                className={({ isActive }) =>
                  isActive
                    ? "noSelect font-bold text-[#F2932C]"
                    : "noSelect text-[#C9CBCE]"
                }
              >
                ABOUT
              </NavLink>
            </li>
            <li class="rounded-sm hover:bg-[#424242] p-[3px]">
              <NavLink
                to="/guestbook"
                className={({ isActive }) =>
                  isActive
                    ? "noSelect font-bold text-[#F2932C]"
                    : "noSelect text-[#C9CBCE]"
                }
              >
                GUESTBOOK
              </NavLink>
            </li>
            <li class="rounded-sm hover:bg-[#424242] p-[3px]">
              <NavLink
                to="/blog"
                className={({ isActive }) =>
                  isActive
                    ? "noSelect font-bold text-[#F2932C]"
                    : "noSelect text-[#C9CBCE]"
                }
              >
                BLOG
              </NavLink>
            </li>
          </ul>
          <ul class="flex space-x-6 md:ml-2">
            <li class="rounded-sm hover:bg-[#424242] p-[3px]">
              <NavLink
                to="/contact"
                className={({ isActive }) =>
                  isActive
                    ? "noSelect font-bold text-[#F2932C]"
                    : "noSelect text-[#C9CBCE]"
                }
              >
                CONTACT
              </NavLink>
            </li>
            <li class="rounded-sm hover:bg-[#424242] p-[3px]">
              <NavLink
                to="/resume"
                className={({ isActive }) =>
                  isActive
                    ? "noSelect font-bold text-[#F2932C]"
                    : "noSelect text-[#C9CBCE]"
                }
              >
                RESUME
              </NavLink>
            </li>
            <li class="rounded-sm hover:bg-[#424242] p-[3px]">
              <NavLink
                to="/tools"
                className={({ isActive }) =>
                  isActive
                    ? "noSelect font-bold text-[#F2932C]"
                    : "noSelect text-[#C9CBCE]"
                }
              >
                TOOLS
              </NavLink>
            </li>
          </ul>
        </div>
        {/* <!-- Dark Mode Toggle Icon --> */}
        <div class="rounded-md bg-[#424242] p-2 hover:cursor-pointer hover:border-2 hover:border-solid hover:border-[#555A65] my-auto ml-3">
          <img
            width="18"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABmJLR0QA/wD/AP+gvaeTAAACn0lEQVRIidWXz0sUYRjHP8/sq+sSBQUZWaewvBhEUKeKREHYdVWG8p73/Gc8dQi6trjTzq67JBSDaERSeNCT1KVOWRc1onJmni5umNv8WFeKvqdl5vs8n32eeZjnHfjfVKnWX1Wq9ZeHjTcdsK93EIvVSfCRg2u12rlSqZTrNPmDB6+7XNftSwV2XbcvUOtdd8+xFcdZ6I1KKugyylLUfcdZ6D3T9/GNinlXLj892xp/QJ7n9Wx9+bqCchlYD30zbNujm5FlRUAt4z8HBhHWujNyLZ/Pf9/vaal4aGjoW7hrRoB1YNDK+HPtQAEyZrcMDALr4a4ZOQiFiKm27dFNx1kYtozvIHz4vZJgBtUCwsW9yxuq1DUws83OKPIeeBH6xo7qVkuro+S683dV5CFwPMKyg8j0ZDGfqkOpwHvQxyn8ishUGngieG9Q3hJd6UFt/zDSP5XPf4ozJb5ALBPMtAEFOJENwvuJeZMMgo61AQVAVRJjDIBbnV9S5MY+2PLE+NhNAIUL7YKB/uaPqNx/rFhV9BCw31JE3tjLnThcFbe+inClTfDq5Hjhapwh8RkrzLcJBaSW5EgGB2YW2EnPZCsjwWzHYNse3URkmpjntk8qoU4Xi8XPHYMBJov5OUSmgO1Ik7AlqncmJsacNDkjh8txFnqtLv8JysbkeOEeQKnROJ0NwvuhWgUhvASgWBsC8xkJZpuVPqk1Holqf9tLYv8+FVicGC/cTlNFU5VqfRG4Rcw+b2l1qVTK/VrisB74ZqodKEDom7s093mX/+xPx6gWcC6XO4UwgLAWd/qIO97a9uhm6JthhDWUgWw2ezLVPy43Guc9z+uJ81Sqda1U67GT7nleT7nROJ8KmlZpwHH6Z+fqQ39JCLp8BMvk7+snq7wVNftgBYkAAAAASUVORK5CYII="
            alt="labalaba"
          />
        </div>
      </div>
    </div>
  );
};

export default NavLinks;
