import React from "react";

const Inspiration = () => {
  return (
    <div className="bg-[#121212] text-[#CBC3CE] font-primary max-w-xl w-[560px] justify-center top-0 pt-5">
      <h1 className="text-2xl sm:text-4xl font-serif italic font-bold text-white my-14">
        <span className="text-[#F2932C]">Inspiration</span> to this site
      </h1>
      <article className="flex flex-col text-justify text-sm gap-4">
        <p>
          It was the 10th of July when I asked&nbsp;
          <a
            href="https://bhaskarrijal.me"
            className="font-bold italic underline"
            target="none"
          >
            my cousin
          </a>
          &nbsp;what next should I learn because I was bored with Python. He
          suggested me to learn React JS and become a full stack developer.
          First I wasn't very much interested but when I found out that it was
          used to create Facebook, Instagram, Netflix and many other big
          applications, I couldn't resist.
        </p>
        <p>
          I had just got my citizenship so I was eligible to get a free .com.np
          domain name too. So he suggested me to learn React JS and get onto
          creating a portfolio. I watched two free beginner courses on YouTube
          then set out to create a portfolio by watching a video, and again
          create another portfolio, this time with Tailwind and I was ready.
        </p>
        <p>
          I asked him what kind of site shall I make. He replied that I should
          take inspirations form some minimal blogs. I looked at some and each
          of them was better than other. I couldn't decide. So once again I went
          seeking his help. He told me to make something similar to his
          portfolio.
        </p>
        <p>
          I decided to create a complete clone of his protfolio but how can I. I
          tried my best and ended up with what you are looking at currently. So
          my inspiration is&nbsp;
          <a
            href="https://bhaskarrijal.me"
            className="font-bold underline italic"
            target="none"
          >
            Bhaskar Rijal's Porfolio
          </a>
          , but technically my design inspirations were&nbsp;
          <a
            href="https://leerob.io/"
            className="font-bold underline italic"
            target="none"
          >
            Lee Robinson's Portfolio
          </a>
          &nbsp;and&nbsp;
          <a
            href="https://www.theminimalists.com/"
            className="font-bold underline italic"
            target="none"
          >
            The Minimalists Blog
          </a>
          &nbsp;technically as&nbsp;
          <a
            href="https://bhaskarrijal.me/inspiration"
            className="font-bold underline italic"
            target="none"
          >
            his inspirations
          </a>
          &nbsp;were them.
        </p>
        <p>
          So, I guess that's almost it about the design inspirations. I hope
          you'll find this site as a part of your design inspiration too. Very
          soon, I'll take the source code of this site public. Till then, I have
          listed the mini-stack that I used to make this site below.
        </p>
      </article>
      <h1 className="text-xl sm:text-2xl font-serif italic font-bold text-white my-14">
        <span className="text-[#F2932C]">What</span> did I use to create this
        site
      </h1>
      <div className="flex flex-col font-serif italic text-white">
        <div className="flex flex-row py-5 border px-4 border-[#374151] border-b-0 hover:bg-[#262626]">
          <span className="font-bold">Frontend</span>: React JS
        </div>
        <div className="flex flex-row py-5 border px-4 border-[#374151] border-b-0 hover:bg-[#262626]">
          <span className="font-bold">Styling</span>: Tailwind, Flowbite
        </div>
        <div className="flex flex-row py-5 border px-4 border-[#374151] border-b-0 hover:bg-[#262626]">
          <span className="font-bold">Backend</span>: Firebase
        </div>
        <div className="flex flex-row py-5 border px-4 border-[#374151] border-b-0 hover:bg-[#262626]">
          <span className="font-bold">Blog</span>: Sanity
        </div>
        <div className="flex flex-row py-5 border px-4 border-[#374151] border-b-0 hover:bg-[#262626]">
          <span className="font-bold">Email Engine</span>: Email JS
        </div>
        <div className="flex flex-row py-5 border px-4 border-[#374151] border-b-0 hover:bg-[#262626]">
          <span className="font-bold">Icons</span>: React-Icons
        </div>
        <div className="flex flex-row py-5 border px-4 border-[#374151] border-b-0 hover:bg-[#262626]">
          <span className="font-bold">Other</span>: React Spinners Kit
        </div>
        <div className="flex flex-row py-5 border px-4 border-[#374151] hover:bg-[#262626]">
          <span className="font-bold">Cooking</span>: Spotify API
        </div>
      </div>
    </div>
  );
};

export default Inspiration;
