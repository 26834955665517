import React from "react";
import {
  BsTwitter,
  BsGithub,
  BsFacebook,
  BsLinkedin,
  BsGlobe,
} from "react-icons/bs";
import { BiMailSend } from "react-icons/bi";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <div className="bg-[#121212] text-[#CBC3CE] font-primary max-w-xl w-[560px] justify-center top-0 pt-5">
      <h2 className="text-2xl md:text-4xl font-serif font-bold italic my-5">
        <span className="text-[#F2932C]">Everything</span> you need to know
      </h2>
      <div className="flex justify-between items-center my-10">
        <div>
          <ul>
            <li className="flex items-center">
              <BsTwitter />
              &nbsp;
              <a
                href="https://twitter.com/kaushalSym"
                className="font-serif italic underline text-[#F2932C]"
              >
                @kaushalSym
              </a>
            </li>
            <li className="flex items-center">
              <BsGithub />
              &nbsp;
              <a
                href="https://github.com/kaushalrijal"
                className="font-serif italic underline text-[#F2932C]"
              >
                @kaushalrijal
              </a>
            </li>
            <li className="flex items-center">
              <BsFacebook />
              &nbsp;
              <a
                href="https://m.facebook.com/rijalkaushal"
                className="font-serif italic underline text-[#F2932C]"
              >
                @rijalkaushal
              </a>
            </li>
            <li className="flex items-center">
              <BsLinkedin />
              &nbsp;
              <a
                href="https://www.linkedin.com/in/kaushal-rijal-772902213"
                className="font-serif italic underline text-[#F2932C]"
              >
                @kaushalrijal
              </a>
            </li>
            <li className="flex items-center">
              <BsGlobe />
              &nbsp;
              <Link
                to="/"
                className="font-serif italic underline text-[#F2932C]"
              >
                https://kaushalrijal.com.np
              </Link>
            </li>
            <li className="flex items-center">
              <BiMailSend />
              &nbsp;
              <a
                href="mailto:kaushalrijal091@gmail.com"
                className="font-serif italic underline text-[#F2932C]"
              >
                kaushalrijal091@gmail.com
              </a>
            </li>
          </ul>
        </div>
        <div className="text-9xl">&#125;</div>
        <div className="rounded-full overflow-hidden w-[140px] h-[140px]">
          <img
            src="https://thispersondoesnotexist.com/image"
            alt="some random pic for now might forget to change"
            width="140px"
          />
        </div>
      </div>
      <div className="text-sm">
        <article className="flex flex-col text-justify gap-3">
          <p>
            Hello my name is Kaushal Rijal, a 17 years old high school student
            based in Jhapa, Nepal. I don't want to bore you so let's get to
            interesting bit about me.
          </p>
          <p>
            When I was 12 my cousin introduced me to HTML. I was really
            fascinated by it. Since then there has been no looking back. Five
            years later now I know how to program in 5 different languages.
          </p>
          <p>
            I had been programming for some time but as the COVID19 became a
            pandemic, I took that oppurtinity to boost my programming skills.
            During that time, I learnt programming like never before.
          </p>
          <p>
            The scope of programming languages is crazy and I absolutely love
            it. I specialize in Web, Android and Mobile app development(idk why
            i'm saying it tho, I'm not looking for any jobs xD).
          </p>
        </article>
      </div>
    </div>
  );
};

export default About;
