import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "../img/logo_dark.png";

const NavLogo = () => {
  return (
    <div class="mx-auto justify-center p-2 sm:mx-0 max-w-xl">
      <NavLink to="/">
        <img src={Logo} width="190" alt="logo laba" />
      </NavLink>
    </div>
  );
};

export default NavLogo;
